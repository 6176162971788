.pm {
	.loading {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, 50%);
	}

	.btn {
		button {
			width: 150px;
			background-color: #fff;
			color: #14213d;
			padding: 10px;
			margin-right: 10px;

			svg {
				margin-right: 10px;
			}
		}
	}

	.c {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		.c-c {
			display: flex;
			justify-content: center;
			padding: 10px;
			width: 100%;
		}
		.img-c {
			width: 450px;
			padding: 10px;
			height: 300px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.cc {
			width: 60%;

			display: flex;
			flex-direction: column;
			justify-content: space-between;

			padding: 10px;
		}

		.desc {
			height: 50%;
			overflow-y: auto;
		}
	}

	.ab {
		background: #14213d;
		color: #fff;

		span {
			max-width: 0;
			-webkit-transition: max-width 1s;
			transition: max-width 1s;
			display: inline-block;
			vertical-align: top;
			white-space: nowrap;
			overflow: hidden;
		}

		svg {
			margin: 10px;
		}

		&:hover {
			padding-right: 10px;
			span {
				max-width: 7rem;
			}
		}
	}

	.bi {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
		filter: blur(8px);
		-webkit-filter: blur(8px);
	}

	@media screen and (max-width: 1024px) {
		.for {
			margin-right: 0;
		}
	}


	@media screen and (max-width: 820px) {
		height: auto;

		.c {
			top: 10%;
		}

		.h-text {
			margin-bottom: 0;
			margin-top: 20px;
		}

		.pc {
			height: auto;
		}
		.c-c {
			flex-direction: column;

			.cc {
				width: 100%;
				height: auto;
			}

			.img-c {
				width: 400px;
				height: 250px;
				margin: auto;
			}

			.btn {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin: 10px auto;
			}

			.desc {
				height: auto;
			}
		}
		.cc {
			width: 100%;
		}

		.for,
		.back {
			display: none;
		}
	}
	@media screen and (max-width: 400px) {
		.c-c {
			.img-c {
				width: 90%;
			}
		}
		.desc {
			font-size: 1.2rem;
			overflow-y: auto;
		}

		.c-p {
			top: 20px;
			right: 20px;
		}
	}
}

/* project2 */
.p2 {
.ss::-webkit-scrollbar-thumb {
	background-color: #284b63;
	border: 4px solid transparent;
	border-radius: 8px;
	background-clip: padding-box;
}

.ss::-webkit-scrollbar {
	width: 10px;
}


	.btn2 {
		display: flex;
		width: 100%;
	}
	.hi {
		opacity: 0;
		background: rgba(0, 0, 0, 0.5);

		.i {
			background: rgba(0, 0, 0, 0.8);
			cursor: pointer;
			color: #fff;
			opacity: 0.9;
		}

		&:hover {
			opacity: 1;
		}
	}

	.pb {
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

		.cl {
			svg {
				cursor: pointer;
				color: #284b63;
				transition: all 0.2s ease-in-out;

				&:hover {
					color: #335c67;
				}
			}
		}
	}

	@media screen and (max-width: 900px) {
		.a {
			flex-wrap: wrap;
			justify-content: center;
		}

		.b {
			width: 80%;
			margin: 10px auto;
		}

		.cc {
			width: 90%;
		}

		.btn {
			justify-content: center;
		}
	}

	@media screen and (max-width: 640px) {
		padding-top: 20px;

		.gp {
			width: 40%;

			.g {
				height: 100px;
			}
		}

		.cc {
			height: auto;
		}

		.btn2 {
			margin: 10px auto;
			justify-content: center;
			flex-wrap: wrap;

			button {
				min-width: auto;
				width: auto;
				margin: 5px auto;
			}
		}
	}

	@media screen and (max-width: 400px) {
		.gp {
			width: 80%;

			.g {
				height: 130px;
			}
		}

		.p2-img {
			width: 100%;
			height: 150px;
		}

		.btn2 {
			button {
				width: 100%;
			}
		}
	}
}
