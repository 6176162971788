.wrapper {
	display: flex;
	width: 100%;
	padding: 40px 0 0 0;

	@media screen and ( max-width: 640px ) {
		padding: 50px 0 0 0;

		.copyright {
			display: none;
		}
	}

	@media screen and (max-width: 400px){
		padding: 0;
	}
}

.app__wrapper {
	flex: 1;
	width: 100%;
	flex-direction: column;
}

.pink {
	background-color: #fff;
}

.app__social {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
	z-index: 2;

	.icon {
		background-color: #da2879;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

		&:hover {
			background-color: #b76935;
		}
	}

	p {
		background-color: rgba(0, 0, 0, 0.9);
		padding: 4px 8px;
		border-radius: 5px;
		padding-bottom: 7px;
		font-size: 14px;

		bottom: 3px;
		left: 45px;
	}

	@media screen and (max-width: 900px) {
		display: none;
	}
}

.home-social {
	/*	background: linear-gradient(
			170deg,
			rgba(49, 57, 73, 0.8) 20%,
			rgba(49, 57, 73, 0.5) 20%,
			rgba(49, 57, 73, 0.5) 35%,
			rgba(41, 48, 61, 0.6) 35%,
			rgba(41, 48, 61, 0.8) 45%,
			rgba(31, 36, 46, 0.5) 45%,
			rgba(31, 36, 46, 0.8) 75%,
			rgba(49, 57, 73, 0.5) 75%
		),
		linear-gradient(
				45deg,
				rgba(20, 24, 31, 0.8) 0%,
				rgba(41, 48, 61, 0.8) 50%,
				rgba(82, 95, 122, 0.8) 50%,
				rgba(133, 146, 173, 0.8) 100%
			)
			#313949; */
	color: #fff;

	&:hover {
		background: #da2879;
	}
}

.home-social, .projects-social, .contact-social, .projects-social, .skills-social {
	font-size: 1.3rem;

	&:hover{
		font-size: 1.2rem;
	}
}

.about-social {
	background-color: #2190ff;
	color: #fff;

	&:hover {
		background-color: rgba(33, 144, 255, 0.9);
	}
}

.projects-social {
	color: #fff;

	&:hover {
		background: #000;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
	}
}

.skills-social {
	color: #2d3236;
}

.home-background {
	background: rgb(13, 17, 23);
	color: #fff;
}

.about-background {
	background: #f5f9ff;
}

.skills-background {
	background: #f5f9ff;
}

.projects-background {
	background-color: #14213d;
	color: #fff;
}

.contact-background {
	background-image: url(../../assets/bgWhite.png);
	background-size: cover;
	background-position: center;
	background-repeat: repeat;
}

.wr-flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
