.app__header {
	max-width: 1500px;

	.njs {
		top: 0;
	}

	.r {
		right: 20px;
		top: 80px;
	}

	.s {
		right: 50px;
		bottom: 10px;
	}

	.js {
		top: 60%;
		right: 10%;
	}

	.rd {
		bottom: 0;
	}

	.bc {

		.btn {
			background: #da2879;
			padding: 10px 15px;
			margin-right: 30px;
			border: none;
			margin: 10px;
			font-size: 16px;
			font-weight: 600;
			color: #fff;
			cursor: pointer;
			outline: none;
			text-transform: none;
			overflow: hidden;
			border-radius: 5px;
			font-family: "Poppins", sans-serif;
			transition: all 1.5s ease-in-out;

			&:hover {
				background: #b76935;
				color: #fff;
			}
		}

		.btn::before {
			--size: 0;
			content: "";
			position: absolute;
			left: var(--x);
			top: var(--y);
			width: var(--size);
			height: var(--size);
			background: radial-gradient(
				circle closest-side,
				rgba(255, 255, 255, 0.3),
				transparent
			);
			transform: translate(-50%, -50%);
			transition: width 0.2s ease, height 0.2s ease;
		}

		.btn:hover::before {
			--size: 60px;
		}
	}

	.mt {
		top: 0px;
		left: 0px;
	}

	@media screen and (max-width: 1024px) {
		.img1{
			padding: 10px;
			margin-left: 10px;
		}
	}

	@media screen and (max-width: 640px){
		.img1 {
			display: none;
		}

		.img2 {
			display: block;
			margin: auto;
			width: 90%;
			height: 300px;
		}

		.sk {
			position: static;
			margin:auto;
		}
		.rc{
			height: auto;
		}
		.bc {
			margin: 10px auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.mt {
			display: none;
		}
	}

	@keyframes App-logo-spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	@media (prefers-reduced-motion: no-preference) {
		.App-logo {
			animation: App-logo-spin infinite 20s linear;
		}
	}
}
