.app__about {
	.sy {
		overflow-y: scroll;
	}

	.sy::-webkit-scrollbar-thumb {
		background-color: rgba(33, 144, 255, 0.6);
	}

	.p-t {
		font-size: 18px;
		padding: 0;
		margin: 10px 0;


		span {
			color: #2190ff;
			cursor: pointer;

			&:hover {
				color: #0066ff;
			}
		}
	}

	.button {
		button {
			background-color: #2190ff;
			color: #fff;
			margin: 10px;
			text-transform: none;
			font-size: 16px;
			padding: 10px 15px;

			&:hover {
				background-color: rgba(33, 144, 255, 0.9);
			}
		}
		.sh {
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		}
	}

	.popup {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		background-color: #fff;
		width: 300px;
		height: 170px;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		border: 2px solid #2190ff;

		padding: 10px;
		border-radius: 10px;

		text-align: center;

		flex-direction: column;
		align-items: center;
		justify-content: center;

		.b-c {
			button {
				text-transform: none;
				letter-spacing: 0.1rem;
				border: 2px solid #2190ff;
				margin: 5px;
			}

			button:nth-child(1) {
				background-color: #2190ff;
				color: #fff;
			}
		}
		.form {
			display: flex;
			flex-direction: column;

			input {
				outline: none;
				border: 2px solid #2190ff;
				border-radius: 5px;
				width: 150px;
				height: 38px;
				padding: 0 10px;
				margin-right: 10px;
			}

			button {
				border: 2px solid #2190ff;
				height: 38px;
				color: #2190ff;

				&:hover {
					background: #2190ff;
					color: #fff;
				}
			}
		}

		.btn {
			border: 1px solid rgba(0, 0, 0, 0.1);
			margin: 10px;
			color: #000;
		}
	}

	.close {
		width: 30px;
		height: 30px;
		border-radius: 50%;

		background-color: rgba(0, 0, 0, 0.1);

		svg {
			color: rgba(0, 0, 0, 0.5);
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.2);

			svg {
				color: rgba(0, 0, 0, 0.3);
			}
		}
	}

	.forfun {
		width: 400px;
		height: 330px;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;

		.fbi {
			top: 0;
			bottom: 0;
			margin: auto;
		}

		img {
			border-bottom-left-radius: 20px;
			border-bottom-right-radius: 20px;
		}
	}

	.icon-img {
		font-size: 1.5rem;
		user-select: none;

	}

	.btn-container {
		.yes {
			background: #2190ff;
			color: #fff;

			&:hover {
				background: rgba(33, 144, 255, 0.9);
			}
		}
	}

	@media screen and (max-width: 900px) {
		height: auto;
	}

	@media screen and (max-width: 400px) {

		.button {
			button {
				width: 90%;
			}
		}
	}
}
