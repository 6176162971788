@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@1&display=swap");

.app__navbar {
	width: 100%;
	height: 50px;

	position: fixed;
	z-index: 10;

	.button {
		color: #fff;
	}

	display: flex;
	align-items: center;
	justify-content: space-between;

	.logo {
		font-size: 1rem;
		font-weight: 600;
		text-transform: uppercase;
		margin-left: 20px;
		font-family: "Poppins", sans-serif;
	}

	@media screen and (max-width: 700px) {
		justify-content: center;
		.logo {
			display: none;
		}
	}

	@media screen and (max-width: 400px) {
		display: none;
	}
}

.default {
	background: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);

	.button {
		color: #fff;
	}

	.slider {
		background-color: #fff;
	}
}

.home {
	background: rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);

	.button {
		color: #fff;
	}

	.slider {
		background-color: #fff;
	}
}

.about {
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	.button {
		color: #2d3236;
	}

	.slider {
		background-color: #000;
	}
}


.skills {
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	.button {
		color: #2d3236;
	}

	.slider {
		background-color: #000;
	}
}

.projects-blur {
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	box-shadow: 0 0 10px rgba(0,0,0,.1);
	.button {
		color: #fff;
	}

	.slider {
		background-color: #fff;
	}
}

.contact-blur {
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	.button {
		color: #2d3236;
	}

	.slider {
		background-color: #2d3236;
	}
}


.projects {
	.button {
		color: #fff;
	}

	.slider {
		background-color: #fff;
	}
}


.contact {
	.button {
		color: #2d3236;
	}

	.slider {
		background-color: #2d3236;
	}
}

.nav {
	.button {
		color: #fff;
	}

	.slider {
		background-color: #fff;
	}
}

.app__link {
	height: 100%;
	margin-right: 20px;

	.l-btn {
		color: #fff;
	}

	.d-btn {
		color: #000;
	}
	.bg-dark {
		background-color: #000;
	}

	.button {
		text-transform: none;
		height: 100%;
		width: auto;
		border-radius: 0;
		list-style: none;
		padding: 0;
		padding: 0 20px;

		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
		}
	}
	a {
		text-decoration: none;
		height: 100%;
		width: 100%;
	}
}

.slider {
	height: 2px;
	transition: all 0.4s ease-in-out;
	position: absolute;
	bottom: 0;
}

.nav-logo, .home-logo, .projects-logo {
	color: #fff;
}

.contact-logo, .skills-logo, .about-logo {
	color: #2d3236;
}
