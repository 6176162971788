@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
.app__flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.pr {
	position: relative;
}

.cp {
	cursor: pointer;
}

.tr {
	transition: all 0.3s ease-in-out;
}

.mh-100 {
	min-height: 100vh;
}

.pa {
	position: absolute;
}

.wh {
	color: #fff;
}

.black {
	color: #000;
}

.ma {
	margin: auto;
}

.n-shadow {
	box-shadow: 0 0px 12px 0 rgba(31, 38, 135, 0.17);
}

.h {
	font-family: "Poppins", sans-serif;
	font-size: 2.5rem;
	font-weight: 800;
}

.p {
	font-family: "Poppins", sans-serif;
	font-size: 1em;
	font-weight: 500;
}

.ri {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.z {
	z-index: 9;
}
.ss::-webkit-scrollbar-thumb {
	background-color: #284b63;
	border: 4px solid transparent;
	border-radius: 8px;
	background-clip: padding-box;
}

.ss::-webkit-scrollbar {
	width: 16px;
}

.nt {
	font-size: 1.5rem;
}

/* loading */
.loader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;  
}


.inner2 {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;  
}

.inner2.one2 {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #14213d;
}

.inner2.two2 {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #14213d;
}

.inner2.three2 {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #14213d;
}
.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #EFEFFA;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #EFEFFA;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #EFEFFA;
}

.lrauto {
	left: 0;
	right: 0;
	margin: auto;
}

.fj {
	display: flex;
	justify-content: center;
}

.t {
	color: #212529;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@for $i from 0 through 100 {
	.b-#{$i} {
		bottom: #{$i}px;
	}
}

@for $i from 0 through 1 {
	.o-#{$i} {
		opacity: #{$i};
	}
}

@for $i from 0 through 100 {
	.t-#{$i} {
		top: #{$i}px;
	}
}

@for $i from 0 through 100 {
	.l-#{$i} {
		left: #{$i}px;
	}
}

@for $i from 0 through 100 {
	.r-#{$i} {
		right: #{$i}px;
	}
}

@for $i from 0 through 100 {
	.m-#{$i} {
		margin: #{$i}px;
	}
}

@for $i from 1 through 100 {
	.mb-#{$i} {
		margin-bottom: #{$i}px;
	}
}

@for $i from 1 through 1000 {
	.mr-#{$i} {
		margin-right: #{$i}px;
	}
}


@for $i from 1 through 100 {
	.mt-#{$i} {
		margin-top: #{$i}px;
	}
}

@for $i from 1 through 100 {
	.ml-#{$i} {
		margin-left: #{$i}px;
	}
}

@for $i from 1 through 200 {
	.w-#{$i} {
		width: #{$i}#{"%"};
	}
}

@for $i from 1 through 100 {
	.h-#{$i} {
		height: #{$i}#{"%"};
	}
}

@for $i from 1 through 1000 {
	.wpx-#{$i} {
		width: #{$i}px;
	}
}

@for $i from 1 through 1000 {
	.hpx-#{$i} {
		height: #{$i}px;
	}
}

@for $i from 1 through 100 {
	.bdr-#{$i} {
		border-radius: #{$i}#{"%"};
	}
}

@for $i from 1 through 100 {
	.bdrr-#{$i} {
		border-radius: #{$i}px;
	}
}

@for $i from 1 through 100 {
	.p-#{$i} {
		padding: #{$i}px;
	}
}

@for $i from 1 through 100 {
	.pb-#{$i} {
		padding-bottom: #{$i}px;
	}
}

@for $i from 1 through 100 {
	.pt-#{$i} {
		padding-top: #{$i}px;
	}
}

@for $i from 1 through 500 {
	.pl-#{$i} {
		padding-left: #{$i}px;
	}
}

@for $i from 1 through 500 {
	.pr-#{$i} {
		padding-right: #{$i}px;
	}
}

@for $i from 1 through 100 {
	.br-#{$i} {
		border-radius: #{$i}px;
	}
}
@for $i from 1 through 100 {
	.fs-#{$i} {
		font-size: #{$i}rem;
	}
}
@for $i from 1 through 1000 {
	.fw-#{$i} {
		font-weight: #{$i};
	}
}
