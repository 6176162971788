.window {
	background: rgb(224, 231, 241);
	background: linear-gradient(
		127deg,
		rgba(224, 231, 241, 1) 0%,
		rgba(235, 239, 248, 1) 24%,
		rgba(223, 230, 240, 1) 35%,
		rgba(245, 249, 255, 1) 100%
	);

	.ani {
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
	}

	.t {
		margin-bottom: 10px;
	}
	.rr {
		border: 3px solid;
		-webkit-text-stroke: 1px red;
		text-shadow: 0 0 3px #fff, 0 0 5px #0000ff;
		color: #fff;
	}

	.rrs {
		height: auto;
		padding: 10px;
		white-space: nowrap;
		color: #000;
		margin: 5px;

		display: flex;
		flex-wrap: wrap;
	}

	.cb {
		button {
			border-top-right-radius: 30px;
			border-bottom-right-radius: 30px;
		}
		input {
			outline: none;
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
		}
	}

	@media screen and (max-width: 640px){
		.fm {
			flex-direction: column;
			margin: auto;
			width: 80%;
		}
	}
}
	.tc {
		width: 30px;
		height: 30px;
		object-fit: cover;
		border-radius: 4px;
		margin-right: 10px;
	}


